import React from 'react';

function About() {
    return (
        <div>
            About Page
        </div>
    );
}

export default About;
